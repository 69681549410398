const hybridCdnList = [
  {
    title: 'hybridCdnList.item1.title',
    content: 'hybridCdnList.item1.content'
  },
  {
    title: 'hybridCdnList.item2.title',
    content: 'hybridCdnList.item2.content'
  },
  {
    title: 'hybridCdnList.item3.title',
    content: 'hybridCdnList.item3.content'
  }
]

export default hybridCdnList
