import React from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import SimpleAccordion from 'components/accordion/simple'
import PosterCard from 'components/card/poster'
import NewsCard from 'components/card/news'
import ZoomGallery from 'components/gallery/zoom'
import I18nImage from 'components/gallery/i18nImage'

import streamFunctions from '/static/data/feature.static'
import hybridCdnList from '/static/data/hybridCdnList.static'
import newsList from '/static/data/newsList.static'

const HybirdCdnDeliveryPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: 'Hybrid & P2P CDN Solutions for Video Delivery - Mlytics',
            metaDescription:
              "Experience faster and more reliable video delivery with Mlytics' hybrid & P2P CDN solutions. Optimize your video delivery today with our scalable platform",
            metaUrl: 'https://www.mlytics.com/video-stream/hybrid-cdn-p2p/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2022/06/logo_Mlytics_regular.png'
            }
          }}
        />
        <section className="container flex flex-col md:flex-row mb-20 mt-10">
          <div className="flex-1 my-10">
            <div className="px-4">
              <h1 className="text-primary-600">{t('hybridCdnP2P.section1.title')}</h1>
              <p className="py-5 text-gray-600">{t('hybridCdnP2P.section1.description')}</p>
              <div className="flex flex-col lg:flex-row gap-3 my-1">
                <Link to="/signup/" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
                  {t('button.freeTrial.0')}
                </Link>
                <Link
                  to="/lp/get-a-demo/"
                  className="btn btn-bold btn-ghost btn-orange inline-block"
                  style={{ width: 208 }}
                >
                  {t('button.getDemo.0')}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex justify-center flex-1 px-8">
            <I18nImage
              src="https://static.mlytics.com/images/website/hybrid_cdn_mesh_manifest.png"
              alt={t('hybridCdnP2P.section1.imageAlt')}
              style={{ height: 'auto', maxWidth: '100%' }}
            />
          </div>
        </section>
        <section className="bg-primary-500 px-4 md:px-10 py-20">
          <div className="container">
            <h2 className="text-white text-center">{t('hybridCdnP2P.section2.title')}</h2>
            <div className="flex flex-col md:flex-row pt-20">
              <div className="flex-1">
                <h6 className="text-white text-2xl">{t('hybridCdnP2P.section2.list1Title')}</h6>
                <ul className="pl-5 list-disc mb-7 mt-1 text-white">
                  <li>{t('hybridCdnP2P.section2.list1Item1')}</li>
                  <li>{t('hybridCdnP2P.section2.list1Item2')}</li>
                  <li>{t('hybridCdnP2P.section2.list1Item3')}</li>
                </ul>
                <h6 className="text-white text-2xl">{t('hybridCdnP2P.section2.list2Title')}</h6>
                <ul className="pl-5 list-disc mb-7 mt-1 text-white">
                  <li>{t('hybridCdnP2P.section2.list2Item1')}</li>
                  <li>{t('hybridCdnP2P.section2.list2Item2')}</li>
                  <li>{t('hybridCdnP2P.section2.list2Item3')}</li>
                  <li>{t('hybridCdnP2P.section2.list2Item4')}</li>
                </ul>
              </div>
              <div className="flex-1">
                <ZoomGallery>
                  <I18nImage
                    src="https://static.mlytics.com/images/website/augment_cost_effective_solutions.png"
                    alt={t('hybridCdnP2P.section2.imageAlt')}
                    style={{ height: 'auto', maxWidth: '100%' }}
                  />
                </ZoomGallery>
                <div className="mb-2 py-2 text-white">
                  <div className="px-4 py-2 bg-primary-400 w-fit">{t('hybridCdnP2P.section2.description')}</div>
                </div>
                <div className="py-2 text-white">
                  <div className="px-4 py-2 bg-primary-400 w-fit">{t('hybridCdnP2P.section2.description2')}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="px-4 md:px-10 my-20">
          <div className="container">
            <h2 className="text-center text-primary-600 text-5xl">{t('hybridCdnP2P.section3.title')}</h2>
            <p className="py-16 text-gray-600">{t('hybridCdnP2P.section3.description')}</p>
            <div className="flex justify-center my-9">
              <I18nImage
                src="https://static.mlytics.com/images/website/hybrid_cdn_mesh_workflow.png"
                alt={t('hybridCdnP2P.section3.imageAlt')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
            <p className="pt-16 text-gray-600">{t('hybridCdnP2P.section3.description2')}</p>
          </div>
        </section>
        <section className="my-20 px-4 md:px-10">
          <div className="container flex flex-col md:flex-row">
            <div className="flex-1 md:px-8 pb-5">
              <h3 className="text-gray-800 text-3xl">{t('hybridCdnP2P.section4.title')}</h3>
              <p className="py-5 text-gray-600">{t('hybridCdnP2P.section4.description')}</p>
              <SimpleAccordion list={hybridCdnList} />
            </div>
            <div className="flex-1 px-8">
              <ZoomGallery>
                <I18nImage
                  src="https://static.mlytics.com/images/website/hybrid_cdn_mesh_usage_anlytics.png"
                  alt={t('hybridCdnP2P.section4.imageAlt')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
          </div>
        </section>
        <section className="my-20 px-4 md:px-10">
          <div className="container flex flex-col-reverse md:flex-row">
            <div className="flex-1 px-8">
              <ZoomGallery>
                <I18nImage
                  src="https://static.mlytics.com/images/website/hybrid_cdn_cost_saving_analytics.png"
                  alt={t('hybridCdnP2P.section5.imageAlt')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
            <div className="flex-1 md:px-8">
              <h3 className="text-gray-800 text-3xl">{t('hybridCdnP2P.section5.title')}</h3>
              <p className="py-5 text-gray-600">{t('hybridCdnP2P.section5.description')}</p>
            </div>
          </div>
        </section>
        <section className="my-20 px-4 md:px-10">
          <div className="container flex flex-col md:flex-row">
            <div className="flex-1 md:px-8">
              <h3 className="text-gray-800 text-3xl">{t('hybridCdnP2P.section6.title')}</h3>
              <p className="py-5 text-gray-600">{t('hybridCdnP2P.section6.description')}</p>
              <ol className="pl-5 pb-5 text-gray-600" style={{ listStyleType: 'decimal' }}>
                <li>{t('hybridCdnP2P.section6.list1Item1')}</li>
                <li>{t('hybridCdnP2P.section6.list1Item2')}</li>
                <li>{t('hybridCdnP2P.section6.list1Item3')}</li>
              </ol>
            </div>
            <div className="flex-1 px-8">
              <ZoomGallery>
                <I18nImage
                  src="https://static.mlytics.com/images/website/hybrid_cdn_delivery_performance.png"
                  alt={t('hybridCdnP2P.section6.imageAlt')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
          </div>
        </section>
        <section className="my-20 px-4 md:px-10">
          <div className="container flex flex-col-reverse md:flex-row">
            <div className="flex-1 px-8">
              <StaticImage
                src="https://static.mlytics.com/images/website/hybrid_cdn_mesh_support_everywhere.png"
                alt={t('hybridCdnP2P.section7.imageAlt')}
                style={{ height: 'auto', maxWidth: '600px' }}
              />
            </div>
            <div className="flex-1 md:px-8">
              <h3 className="text-gray-800 text-3xl">{t('hybridCdnP2P.section7.title')}</h3>
              <p className="py-5 text-gray-600">{t('hybridCdnP2P.section7.description')}</p>
            </div>
          </div>
        </section>
        <section className="bg-gradient-blue px-4 md:px-10 py-16">
          <h2 className="text-center text-white text-5xl">{t('heading.learnMoreAboutStream')}</h2>
          <div className="flex flex-col md:flex-row justify-center pt-10">
            {streamFunctions.map((item, index) => {
              return index === 3 ? null : (
                <PosterCard
                  className="basis-0 mx-3 mb-3 md:mb-0"
                  key={index}
                  iconName={item.iconName}
                  title={t(item.title)}
                  paragraph={t(item.paragraph)}
                  anchorHref={item.anchorHref}
                  anchorText={t(item.anchorText)}
                />
              )
            })}
          </div>
          <div className="flex flex-col lg:flex-row gap-3 items-center justify-center mt-10">
            <Link to="/signup/" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
              {t('button.freeTrial.0')}
            </Link>
            <Link
              to="/lp/get-a-demo/"
              className="btn btn-bold bg-white border-white text-secondary-600 inline-block"
              style={{ width: 208 }}
            >
              {t('button.getDemo.0')}
            </Link>
          </div>
        </section>
        <section className="hidden xl:block px-10 py-24">
          <h2 className="font-black text-center text-4.5xl text-primary-600">{t('heading.productPlatform')}</h2>
          <div className="flex justify-center mt-16">
            <img
              src="https://static.mlytics.com/images/website/mlytics_platform.svg"
              alt={t('hybridCdnP2P.section9.imageAlt')}
              style={{ height: 'auto', maxWidth: '1260px' }}
            />
          </div>
        </section>
        <section className="p-10">
          <h2 className="text-center text-primary-600 text-6xl mt-10">{t('heading.latestNews')}</h2>
          <div className="flex flex-col md:flex-row items-center justify-center mt-10 mb-20">
            {newsList.map((item, index) => (
              <NewsCard
                key={index}
                className="w-1/4 mx-2 mb-3 md:mb-0"
                flag={t(item.flag)}
                title={t(item.title)}
                subtitle={t(item.subtitle)}
                paragraph={t(item.paragraph)}
                anchorHref={item.anchorHref}
                imageSrc={item.imageSrc}
                imageAlt={t(item.imageAlt)}
              />
            ))}
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default HybirdCdnDeliveryPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
